import React, { useEffect, useRef, useState } from "react";
import { CustomImage } from "../../../components/design-system/CustomImg";
import Dayjs from "dayjs";
import { addComma } from "../../../util/number";
import Text from "../../../components/design-system/Text";
import { API_DEFAULT } from "../../../api/api";
import useIntersectObserver from "../../../components/hooks/useIntersectObserver";
import NoCreditData from "./NoCreditData";
import FeedInfoModal from "../../feed/modal/FeedInfoModal";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { instance } from "../../../api";

export default function FofCreditList({ value }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();

  const getFofListData = async (page: any) => {
    try {
      const res = await instance.get(
        `${API_DEFAULT}/user/info/trade?type=${value}&page=${page}&size=10`,
        {
          params: {
            page,
            size: 10,
          },
        },
      );
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [page, setPage] = useState(0);
  const [commentList, setCommentList] = useState<any>([]);
  const intersectRef = useRef<any>(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  const { isIntersect } = useIntersectObserver(intersectRef, {
    rootMargin: "200px",
    threshold: 0.01,
  });

  const loadMoreCommentData = async () => {
    if (isIntersect) {
      try {
        const data = await getFofListData(page);

        if (data?.length === 0) {
          setIsLastPage(true);
        } else {
          // 여기서 data가 반드시 배열인지 확인
          if (Array.isArray(data)) {
            setCommentList((prevComments: any) => [...prevComments, ...data]);
            setPage((prev) => prev + 1);
          } else {
            // data가 배열이 아닐 때의 처리 로직 추가
            console.error("data is not an array", data);
          }
        }
      } catch (error) {
        console.error("Error loading more comment data:", error);
        // 필요한 경우 에러 상태 설정 또는 사용자에게 피드백 제공
      }
    }
  };

  useEffect(() => {
    loadMoreCommentData();
  }, [isIntersect, isLastPage, page, value]);

  return (
    <>
      {commentList?.length < 1 ? (
        <NoCreditData value={value} />
      ) : (
        commentList?.map((item: any, index: any) => (
          <div className="py-[30px] border-b border-[#EBEBEB]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-6">
                <img
                  src={item?.thumbnail}
                  alt=""
                  className={`${isMobile ? "w-[80px] h-[80px]" : "w-[100px] h-[100px]"} rounded-lg object-cover cursor-pointer`}
                  onClick={() => {
                    if (isMobile) {
                      navigate(`/mobile-feed/${item?.contentId}`, {
                        state: item,
                      });
                    } else {
                      setInfoModal({ show: true, data: item });
                    }
                  }}
                />

                <div>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    fontWeight={700}
                    color={item?.cancel ? "#f87171" : "#42BC7F"}
                  >
                    {item?.description}
                  </Text>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    fontWeight={600}
                    color={"#b2b2b2"}
                  >
                    {Dayjs(item.createTime).format("YYYY.MM.DD")}{" "}
                  </Text>
                  <Text
                    fontSize={isMobile ? 14 : 16}
                    fontWeight={600}
                    color={"#b2b2b2"}
                  >
                    {item?.type === "TICKET" ? "1년 이용권" : "소유권"}
                  </Text>
                </div>
              </div>

              <div className="flex flex-col items-end justify-center">
                <Text
                  fontSize={isMobile ? 12 : 20}
                  fontWeight={600}
                  color="#484848"
                  className={`${item?.cancel && "line-through"} dark:text-darkText ${isMobile && "min-w-[47px]"}`}
                >
                  {addComma(item?.amount)} ₩
                </Text>

                {item?.cancel && (
                  <Text
                    fontSize={isMobile ? 10 : 14}
                    fontWeight={600}
                    color={"#f87171"}
                  >
                    결제 취소
                  </Text>
                )}
              </div>
            </div>
          </div>
        ))
      )}

      {infoModal.show && (
        <FeedInfoModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
      {!isLastPage && <div id="intersectElement" ref={intersectRef}></div>}
    </>
  );
}
