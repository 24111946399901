import React, { useEffect, useState } from "react";
import Text from "../../design-system/Text";
import FillButton from "../../design-system/button/FillButton";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UploadMap from "../../google/UploadMap";
import SearchByGoogle from "../../../lib/modules/google-map/SearchByGoogle";
import { LabelInputView } from "../../design-system/input/LabelInputView";
import { SwitchButton } from "../../design-system/SwitchButton";
import { useMediaQuery } from "react-responsive";
import { useContext } from "../../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getGeocoder,
  getLatLng,
} from "../../../lib/modules/google-map/google-map-util";
import { setGeoPosition } from "../../../store/reducer/geoLocationSlice";
import styled from "styled-components";
import FadeMotionDiv from "../../design-system/motion/FadeMotionDiv";
import FeedModal from "../../design-system/modal/FeedModal";
import { updateLocation } from "../../../api/feed/feed";

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`;

export default function FeedLocationModal({ open, onClose, dataProps }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const location = useLocation();
  // const locationSet = useGeolocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { lat, lng } = useAppSelector((state) => state.geoLocation);
  const [formData, setFormData] = useState<any>();
  const [switchData, setSwitchData] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [viewGeo, setViewGeo] = useState("");
  const [loading, setLoading] = useState(false);

  const [clickPos, setClickPos] = useState({
    lat: lat !== "" ? lat : Number(dataProps?.lat),
    lng: lng !== "" ? lng : Number(dataProps?.lng),
  });

  const onSubmit = async () => {
    // 주소-좌표 변환 객체를 생성합니다
    const res = await getLatLng(keyword);

    dispatch(
      setGeoPosition({
        lat: res?.lat,
        lng: res?.lng,
      }),
    );
  };

  async function onClickPos() {
    const data = {
      lat: clickPos?.lat,
      lng: clickPos?.lng,
    };

    const posRes = await getGeocoder(data);

    if (posRes) {
      setViewGeo(posRes?.address);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      onClickPos();
    }, 500);
  }, [lat, lng, clickPos]);

  useEffect(() => {
    setClickPos({
      lat: lat !== "" ? lat : Number(dataProps?.lat),
      lng: lng !== "" ? lng : Number(dataProps?.lng),
    });
  }, [lat, lng]);

  // onSubmit
  const { mutate, status } = useMutation({
    mutationFn: updateLocation,
    onSuccess: (data) => {
      if (data?.success) {
        onClose();
        dispatch(
          toastFetched({
            show: true,
            text: "위치를 수정했습니다.",
            type: "success",
          }),
        );
        window.location.reload();
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (clickPos?.lat === 0 || clickPos?.lng === 0) {
      setViewGeo("등록된 주소 정보가 없습니다.");
    }
  }, []);

  return (
    <FeedModal
      open={open}
      onClose={onClose}
      headerNone
      width="1000px"
      feed={true}
    >
      <FadeMotionDiv>
        <div
          className={`flex flex-col justify-center  pb-12 pl-12 pr-12 ${isMobile && "max-w-[420px]"}`}
        >
          <div className="flex flex-col justify-start items-start">
            <div
              className={`flex justify-between items-center w-full ${!isMobile && "mt-[80px]"}`}
            >
              <Text className={`title mb-5 dark:text-darkText `}>
                {dataProps?.contentName} 위치 수정
              </Text>
            </div>

            <div className={"mb-4 flex flex-col gap--2"}>
              <Text fontWeight={600} fontSize={14} color="#737373">
                현재 위치 :{" "}
                {dataProps?.address
                  ? dataProps?.address
                  : "등록된 주소 정보가 없습니다."}
              </Text>
              <Text fontWeight={600} fontSize={14}>
                수정 위치 : {viewGeo}
              </Text>
            </div>

            <div className="flex flex-col gap-2 mb-5">
              <Text className="location-info-text dark:text-[#535353]">
                지역 정보
              </Text>
              <Text className="label-1" color="#737373">
                촬영지, 창작지 정보를 등록하면, 검색에 유리해요!
              </Text>
            </div>

            <UploadMap lat={clickPos?.lat} lng={clickPos?.lng} />

            <BorderWrap
              className={`p-6 mt-10 ${isMobile ? "w-full" : "w-[880px]"}`}
            >
              <SearchByGoogle />
            </BorderWrap>

            <BorderWrap
              className={`p-6 mt-10 ${isMobile ? "w-full" : "w-[880px]"}`}
            >
              <LabelInputView
                readOnly={true}
                value={viewGeo}
                setValue={undefined}
                placeholder={""}
                label="가장 최근에 표시된 마커 주소의 정보가 저장됩니다."
                onKeyUp={(event: any) => {
                  if (event.key === "Enter") {
                    !!onSubmit && onSubmit();
                  }
                }}
              />
            </BorderWrap>

            <div className="flex flex-col items-center justify-center w-full mt-10">
              <div className="flex flex-col justify-center items-center gap-4 mb-8">
                <div className="flex items-center gap-3">
                  <SwitchButton
                    value={switchData}
                    onChange={() => setSwitchData(!switchData)}
                  />
                  <Text className="label-1 dark:text-darkText">
                    위치를 모르겠어요!
                  </Text>
                </div>
              </div>

              <FillButton
                disabled={loading}
                onClick={() => {
                  mutate({
                    contentId: dataProps?.contentId,
                    latitude:
                      clickPos?.lat === 0
                        ? ""
                        : clickPos?.lat === "" && switchData === false
                          ? clickPos?.lat
                          : switchData
                            ? ""
                            : clickPos?.lat,
                    longitude:
                      clickPos?.lng === 0
                        ? ""
                        : clickPos?.lng === "" && switchData === false
                          ? clickPos?.lng
                          : switchData
                            ? ""
                            : clickPos?.lng,
                    address:
                      switchData || clickPos?.lat === 0 || clickPos?.lng === 0
                        ? ""
                        : viewGeo,
                  });
                }}
                text={"수정하기"}
                className="w-[360px] mb-4 flex justify-center"
                size="lg"
                loading={loading}
              />

              <Text
                className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                onClick={onClose}
              >
                취소
              </Text>
            </div>
          </div>
        </div>
      </FadeMotionDiv>
    </FeedModal>
  );
}
