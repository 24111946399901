import React from "react";
import { useContext } from "../../../util/useContext";
import { Avatar, Tooltip } from "@material-tailwind/react";
import Text from "../../../components/design-system/Text";
import { CustomImage } from "../../../components/design-system/CustomImg";
import { addComma } from "../../../util/number";
import { checkRole } from "../../../util";
import FillButton from "../../../components/design-system/button/FillButton";
import FillIconButton from "../../../components/design-system/button/FillButtonIcon";
import FollowerCheck from "../../../components/svg/FollowerCheck";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT, API_SOCIAL } from "../../../api/api";
import { useAppDispatch } from "../../../store/store";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { instance } from "../../../api";

export default function OthersAvatarCard({ data, check }: any) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { isLogin, me } = useContext();

  async function userFollower() {
    const payload = {
      artistId: data?.memberId,
    };
    const res = await instance.post(`${API_SOCIAL}/user/social/sub`, payload);
  }

  const { mutate } = useMutation({
    mutationFn: userFollower,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subCheck"],
      });
      queryClient.invalidateQueries({
        queryKey: ["other-profile"],
      });
    },
  });

  return (
    <div className="bg-[#F9F9F9] dark:bg-darkBanner px-6 py-[28px] border border-[#FFFFFF] dark:!border-[#1a1a1a] rounded-lg min-w-[284px] h-full min-h-[500px]">
      <div className="flex flex-col items-center justify-center gap-5">
        <Avatar
          variant="circular"
          alt="profile img"
          src={
            data?.profileImage
              ? data?.profileImage
              : "/img/standbuy/icon/no-profile.svg"
          }
          placeholder={undefined}
          className="border border-[#42BC7F] ring-1 hover:ring-1 ring-[#42BC7F] hover:ring-green-700 p-0.5 w-[147px] h-[147px]"
        />

        <Text
          fontSize={26}
          fontWeight={700}
          color="#1a1a1a"
          className="dark:text-darkText relative"
        >
          {data?.nickname}

          {data?.grade === "ROLE_DIRECTOR" && (
            <div className={"w-[20px] absolute -top-1 -right-6 z-[998]"}>
              <Tooltip
                content={
                  <div>Director 마크 : 스탠바이가 인증한 전문가에요😀</div>
                }
              >
                <img src={"/img/standbuy/icon/dr-badge.svg"} alt="director" />
              </Tooltip>
            </div>
          )}
        </Text>

        {check ? (
          <FillIconButton
            onClick={mutate}
            text="구독중"
            className="w-[110px]"
            icon={<FollowerCheck />}
          />
        ) : (
          <FillButton
            onClick={() => {
              if (isLogin && me?.mobileVerification) {
                mutate();
              } else if (isLogin && !me?.mobileVerification) {
                dispatch(niceModalFetched({ show: true }));
              } else if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              }
            }}
            text="구독"
            className="w-[110px]"
          />
        )}
      </div>

      <div className="w-full h-[1px] bg-[#E6E6E6] my-5"></div>

      <div className="flex flex-col gap-[24px]">
        <div className="flex items-center mx-[20px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/follower.svg"
              color="#A1A1A1"
            />
            <Text
              fontSize={16}
              fontWeight={600}
              color="#717171"
              className="dark:text-darkText"
            >
              구독자
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 dark:text-darkText"
          >
            {data?.subscriberCount}
          </Text>
        </div>

        <div className="flex items-center mx-[18px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/rank-user.svg"
              color="#A1A1A1"
            />
            <Text
              fontSize={16}
              fontWeight={600}
              color="#717171"
              className="dark:text-darkText"
            >
              등급
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 dark:text-darkText"
          >
            {checkRole(data?.grade)}
          </Text>
        </div>

        <div className="flex items-center mx-[20px]">
          <div className="flex items-center gap-[10px] max-w-[120px] min-w-[100px]">
            <CustomImage
              src="/img/standbuy/icon/download.svg"
              color="#A1A1A1"
            />
            <Text
              fontSize={16}
              fontWeight={600}
              color="#717171"
              className="dark:text-darkText"
            >
              다운로드
            </Text>
          </div>
          <Text
            fontSize={20}
            fontWeight={700}
            color="#343434"
            className="ml-6 dark:text-darkText"
          >
            {addComma(data?.salesCount)}
          </Text>
        </div>
      </div>
    </div>
  );
}
