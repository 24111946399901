import React, { useEffect, useRef, useState } from "react";
import { useContext } from "../../../util/useContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { getCookie } from "../../../util/cookies";
import { API_DEFAULT } from "../../../api/api";
import { Avatar } from "@material-tailwind/react";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { LabelInputLength } from "../../../components/design-system/input/LabelInputLength";
import { LabelInputView } from "../../../components/design-system/input/LabelInputView";
import ProfileSetModal from "../../profileEdit/modal/ProfileSetModal";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useAppDispatch } from "../../../store/store";
import FillIconButton from "../../../components/design-system/button/FillButtonIcon";
import { useNavigate } from "react-router-dom";
import ProfileAvatarModal from "../../profileEdit/modal/ProfileAvatarModal";
import ProfileNickModal from "../../profileEdit/modal/ProfileNickModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { onNicknameChange } from "../../../api/profile/profile";
import { mySimple } from "../../../api/info/info";

export default function MyPageEdit() {
  const { me } = useContext();
  const isMobile = useMediaQuery({ maxWidth: 1300 });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { form, get, post } = useAxios();
  const isInfo = getCookie("me");
  const [profileModal, setProfileModal] = useState({ show: false });
  const [profileAvatarModal, setProfileAvatarModal] = useState({ show: false });
  const [profileNickModal, setProfileNickModal] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [file, setFile] = useState<any>();
  const fileInput = useRef(null);
  const { data, status: getStatus } = useQuery({
    queryKey: ["profile-simple"],
    queryFn: mySimple,
  });

  const [image, setImage] = useState<any>();
  const [nickname, setNickname] = useState<any>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleKeyDown = (event: any) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onChange = (e: any) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      setImage(isInfo?.profileImage || "/img/standbuy/icon/no-profile.svg");
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (data?.data?.profileImage !== null) {
      setImage(data?.data?.profileImage);
    } else {
      setImage("/img/standbuy/icon/no-profile.svg");
    }
  }, [data, file]);

  useEffect(() => {
    if (data?.data?.nickname) {
      setNickname(data?.data?.nickname);
    }

    if (data?.data?.email) {
      setEmail(data?.data?.email);
    }

    if (data?.data?.nickname) {
      setNickname(data?.data?.nickname);
    }
  }, [data]);

  async function onProfileImg(data: any) {
    const imgData = {
      profileImage: data ? data : me?.profileImage,
    };
    const res = await form(`${API_DEFAULT}/user/profile/update/image`, imgData);
    if (res?.success) {
      dispatch(
        toastFetched({
          show: true,
          text: res?.data.message,
          type: "success",
        }),
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onProfileImg,
    onSuccess: () => {
      setFile(undefined);
      setProfileAvatarModal({ show: false });
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
    },
  });

  async function onProfileImgReset(data: any) {
    const isConfirmed = window.confirm(
      "지금 프로필 이미지를 변경하면 1주일간 변경할 수 없습니다.",
    );

    if (isConfirmed) {
      const imgData = {
        profileImage: null,
      };
      try {
        const res = await form(
          `${API_DEFAULT}/user/profile/update/image`,
          imgData,
        );
        if (res?.success) {
          alert("프로필 이미지를 초기화하였습니다.");
          window.location.reload();
        } else {
          dispatch(
            toastFetched({
              show: true,
              text: res?.error.message,
              type: "error",
            }),
          );
        }
      } catch (error) {
        dispatch(
          toastFetched({
            show: true,
            text: "서버 요청 중 오류가 발생했습니다.",
            type: "error",
          }),
        );
      }
    }
  }

  const { mutate: onNickname } = useMutation({
    mutationFn: onNicknameChange,
    onSuccess: (data) => {
      setProfileNickModal({ show: false });
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });

      if (data?.success) {
        dispatch(
          toastFetched({
            show: true,
            text: data?.data.message,
            type: "success",
          }),
        );
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: data?.error.message,
            type: "error",
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (status === "pending") {
      setLoading(true);
    } else if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      <div className="flex flex-col gap-10">
        <Text
          fontSize={16}
          color="#303030"
          fontWeight={600}
          className="mt-3 dark:text-darkText"
        >
          내 계정 관리
        </Text>
        <div
          className={`flex ${isMobile ? "flex-col items-center" : "justify-around"} `}
        >
          <div className="flex flex-col items-center">
            <div className="flex items-center">
              <div
                className="relative"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <Avatar
                  variant="circular"
                  alt="profile img"
                  src={image}
                  placeholder={undefined}
                  className="w-[210px] h-[210px] ring-2 hover:ring-2 ring-[#42BC7F] hover:ring-green-700 p-0.5 cursor-pointer ml-[80px] "
                  onClick={() => {
                    //@ts-ignore
                    fileInput.current.click();
                  }}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg, image/svg, image/jpg"
                  name="profile_img"
                  onChange={onChange}
                  ref={fileInput}
                />

                {isHover && (
                  <div
                    className="w-[210px] h-[210px] absolute top-0 left-0 rounded-full flex items-center justify-center ml-[80px]"
                    style={{
                      background:
                        "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
                      pointerEvents: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faPencil} className="text-white" />
                  </div>
                )}
              </div>

              <FillButton
                onClick={() => {
                  setProfileAvatarModal({ show: true });
                }}
                text="적용하기"
                size="lg"
                className="flex justify-center ml-20"
                loading={loading}
                disabled={
                  status === "pending" || file === undefined ? true : false
                }
              />
            </div>

            <div className="flex gap-10 mt-10">
              <LabelInputLength
                width="340px"
                label="닉네임"
                value={nickname}
                setValue={setNickname}
                placeholder={
                  "닉네임은 2~15글자의 한글, 영문, 숫자만 가능합니다"
                }
                maxLength={15}
                onKeyDown={handleKeyDown}
              />

              <FillButton
                onClick={() => {
                  setProfileNickModal({ show: true });
                }}
                text="수정"
                size="lg"
                className="flex justify-center"
                loading={loading}
                disabled={status === "pending" ? true : false}
              />
            </div>

            <Text
              fontSize={12}
              className={"mt-4 cursor-pointer"}
              color={"#686868"}
              fontWeight={500}
              onClick={onProfileImgReset}
            >
              프로필 기본 이미지로 변경
            </Text>
          </div>

          <div
            className={`flex flex-col gap-10 ${isMobile ? "w-[480px]" : "w-[360px]"}  mt-10`}
          >
            {/*<LabelInputView*/}
            {/*  width="100%"*/}
            {/*  value={me?.name}*/}
            {/*  setValue={setEmail}*/}
            {/*  placeholder={"이름"}*/}
            {/*  label="이름"*/}
            {/*  readOnly={true}*/}
            {/*/>*/}

            <LabelInputView
              readOnly={true}
              width={"100%"}
              value={email}
              setValue={setEmail}
              placeholder={"이메일"}
              label="이메일"
            />

            <FillIconButton
              onClick={() => navigate("/password-find")}
              text={"비밀번호 변경하기"}
              className={"cursor-pointer"}
            />
          </div>
        </div>
      </div>
      {profileModal.show && (
        <ProfileSetModal
          open={profileModal.show}
          onClose={() => setProfileModal({ show: false })}
        />
      )}

      {profileAvatarModal.show && (
        <ProfileAvatarModal
          open={profileAvatarModal.show}
          onClick={() => mutate(file)}
          onClose={() => setProfileAvatarModal({ show: false })}
        />
      )}

      {profileNickModal.show && (
        <ProfileNickModal
          open={profileNickModal.show}
          onClick={() => onNickname(nickname)}
          onClose={() => setProfileNickModal({ show: false })}
        />
      )}
    </>
  );
}
