//@ts-nocheck
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  googleMapPanTo,
  isLoadedGoogleApi,
  loadGoogleApi,
} from "./google-map-util";
import { GoogleMapProps } from "./google-map";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useAppDispatch } from "../../../store/store";
import {
  boundsFetched,
  zoomFetched,
} from "../../../store/reducer/mapBoundSlice";
import debounce from "lodash/debounce";

const GoogleMap = forwardRef((props: GoogleMapProps, ref) => {
  const dispatch = useAppDispatch();
  const mapElement = useRef(null);
  const createdMarkers = useRef([]) as any;
  const infoWindows = useRef<google.maps.InfoWindow[]>([]);
  const markerClusterer = useRef<MarkerClusterer | null>(null) as any;
  const mapInstance = useRef<google.maps.Map | null>(null);

  const initMap = useCallback(() => {
    const { google } = window;
    if (!mapElement.current || !google) return;
    infoWindows.current = [];
    createdMarkers.current = [];
    const _mapOptions = Object.assign(
      {
        zoom: 12,
        center: {
          lat: 37.5656,
          lng: 126.9769,
        },
        zoomControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: "greedy",
      },
      props?.mapOptions,
    );
    const map = new google.maps.Map(mapElement.current, _mapOptions);

    mapInstance.current = map;

    map.addListener("zoom_changed", () => {
      const currentZoomLevel = map.getZoom();
      dispatch(zoomFetched({ zoom: currentZoomLevel }));
    });

    map.addListener("bounds_changed", () => {
      const bounds: any = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      dispatch(
        boundsFetched({
          ne: { lat: ne.lat(), lng: ne.lng() },
          sw: { lat: sw.lat(), lng: sw.lng() },
        }),
      );
    });

    map.addListener(
      "mousemove",
      (e: google.maps.MapMouseEvent) =>
        !!props?.onMousemove && props?.onMousemove(e),
    );
    map.addListener("click", (e: google.maps.MapMouseEvent) => {
      const _position: any = {
        lat: e.latLng?.lat(),
        lng: e?.latLng?.lng(),
        zoom: map.getZoom(),
      };
      if (props?.isAddMarker) {
        const newMarker = new google.maps.Marker({
          position: _position,
          map: map,
        });
        createdMarkers.current.push(newMarker);
        markerClusterer.current.addMarker(newMarker);
      }
      infoWindows?.current.forEach((window) => window.close());
      !!props?.onClick && props?.onClick(_position);
    });

    // 초기에는 MarkerClusterer를 생성하지 않고, 마커 업데이트 시 생성합니다.
    // markerClusterer.current = new MarkerClusterer({
    //   map,
    //   markers: createdMarkers.current,
    // });

    !!props?.onInit && props?.onInit(map);
    window.googlemap = map;
    if (props?.positions) window.googlemap.positions = props?.positions;
  }, [props?.mapOptions, dispatch, props]);

  const updateMarkers = useCallback(
    debounce((positions) => {
      if (!mapInstance.current || !positions) return;

      // 기존 마커 제거
      createdMarkers.current.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });
      createdMarkers.current = [];
      infoWindows.current = [];
      if (markerClusterer.current) {
        markerClusterer.current.clearMarkers();
      }

      // 새로운 마커 생성
      positions.forEach((markerData, index) => {
        const offsetLat = markerData.lat + index * 0.0001; // 위도에 약간의 오프셋 추가
        const offsetLng = markerData.lng + index * 0.0001; // 경도에 약간의 오프셋 추가

        const googleMarker = new google.maps.Marker({
          position: { lat: offsetLat, lng: offsetLng },
          map: mapInstance.current,
          icon: props?.markerIcon && {
            url: props?.markerIcon?.url,
            scaledSize: new google.maps.Size(
              props?.markerIcon?.width,
              props?.markerIcon?.height,
            ),
          },
        });

        // 마커에 'count' 값 저장
        googleMarker.count = markerData.count || 1;
        createdMarkers.current.push(googleMarker);

        let content = markerData?.content || "인포윈도우";
        if (markerData?.renderItem) content = markerData.renderItem(markerData);

        if (!markerData?.renderItem && !markerData?.content) return;

        const infoWindow = new google.maps.InfoWindow({
          content: `<div class="google-map-infowindow">${content}</div>`,
        });

        google.maps.event.addListener(infoWindow, "domready", () => {
          !!props?.onInfoWindowDomReady &&
            props?.onInfoWindowDomReady(markerData);
        });

        markerData.infoWindow = infoWindow;
        markerData.marker = googleMarker;
        infoWindows.current.push(infoWindow);

        googleMarker.addListener("click", (data: GoogleMapProps) => {
          infoWindows.current.forEach((info) => info.close());
          infoWindow.open(mapInstance.current, googleMarker);
          !!props?.onMarkerClick && props?.onMarkerClick(data);
        });
      });

      // MarkerClusterer 생성 및 커스텀 렌더러 적용
      markerClusterer.current = new MarkerClusterer({
        map: mapInstance.current,
        markers: createdMarkers.current,
        renderer: {
          render: ({ markers, position }) => {
            // 클러스터에 포함된 모든 마커의 'count' 값을 합산
            const totalCount = markers.reduce(
              (sum, marker) => sum + (marker.count || 1),
              0,
            );

            // 커스텀 클러스터 마커 생성
            return new google.maps.Marker({
              position,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: "#4285F4",
                fillOpacity: 0.7,
                scale: Math.min(totalCount * 2, 60), // 크기를 'count'에 비례하도록 설정
                strokeColor: "white",
                strokeWeight: 1,
              },
              label: {
                text: String(totalCount),
                color: "white",
                fontSize: "14px",
              },
            });
          },
        },
      });
    }, 100), // 100ms 디바운스 적용
    [],
  );

  useImperativeHandle(ref, () => ({
    addMarker: (data: any) => {
      if (!data) return;
      const newMarker = new google.maps.Marker({
        position: data,
        map: window.googlemap,
      });
      createdMarkers.current.push(newMarker);
      markerClusterer?.current?.addMarker(newMarker);
    },
    setCenter: (data: any) => {
      !!data && googleMapPanTo(data);
    },
    setZoom: (data: any) => {
      !!data && window.googlemap.setZoom(data);
    },
  }));

  useEffect(() => {
    if (isLoadedGoogleApi()) return initMap();
    window.initMap = initMap;
    loadGoogleApi({
      key: props?.API_KEY,
      language: "ko",
      region: "KR",
      callback: "initMap",
    });
  }, []);

  useEffect(() => {
    updateMarkers(props.positions);
  }, [props.positions, updateMarkers]);

  return (
    <section
      style={Object.assign({ width: "100%", height: `500px` }, props?.style)}
      ref={mapElement}
    >
      {/* 이곳에 지도가 표시됩니다. */}
    </section>
  );
});

export default GoogleMap;
