// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Button,
  IconButton,
  Input,
  Collapse,
  Chip,
} from "@material-tailwind/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Switcher from "../common/Switcher";
import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ProfileAvatarMenu } from "../common/ProfileAvatarMenu";
import {
  modalFetched,
  niceModalFetched,
} from "../../store/reducer/globalModalSlice";
import { getCookie } from "../../util/cookies";
import { useAxios } from "../../lib/api/useAxios";
import { useContext } from "../../util/useContext";
import { useMediaQuery } from "react-responsive";
import Text from "../design-system/Text";
import JoinEndModal from "../../pages/join/modal/JoinEndModal";
import MobileAvatarModal from "../common/MobileAvatarModal";
import SearchModal from "../common/SearchModal";
import { searchFlagFetched } from "../../store/reducer/mobileSlice";
import { API_DEFAULT } from "../../api/api";
import { signOut } from "../../lib/modules/auth";
import Lottie from "react-lottie";
import LottieData from "../../assets/lottie/notification-V3.json";
import { useQuery } from "@tanstack/react-query";
import { LOCAL_STORAGE } from "../../lib/const";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import { toastFetched } from "../../store/reducer/toastSlice";
import { alarmCheck } from "../../api/alarm/alarm";
import { CustomImage } from "../design-system/CustomImg";

export function CustomNavBar() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { post, del, get } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  // const isLogin = getCookie("isLogin");
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const [openNav, setOpenNav] = useState(false);
  const [keyword, setKeyword] = useState("");
  const { me, isLogin } = useContext();
  const [mobileModal, setMobileModal] = useState({ show: false });
  const [searchModal, setSearchModal] = useState({ show: false });
  const { searchFlag } = useSelector((state: any) => state.mobile);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );

  // const [realtimeData, setRealtimeData] = useState("");
  // //
  // const tokenData = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  //
  // // EventSource를 참조하는 ref 생성
  // const EventSource = EventSourcePolyfill || NativeEventSource;
  // const eventSource = useRef<null | EventSource>(null);
  // //
  // useEffect(() => {
  //   // SSE 연결을 설정하는 함수
  //   const fetchSSE = () => {
  //     eventSource.current = new EventSource(`${API_DEFAULT}/user/alarm/sub`, {
  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //       },
  //       withCredentials: true,
  //     });
  //
  //     // SSE 이벤트 리스너 설정
  //     eventSource.current.addEventListener("alarm", (event: any) => {
  //       const receivedData = event?.data; // 서버로부터 수신한 데이터를 파싱
  //       setRealtimeData(receivedData); // 받은 데이터를 상태로 설정
  //       dispatch(
  //         toastFetched({
  //           show: true,
  //           text: receivedData,
  //           type: "success",
  //         }),
  //       );
  //     });
  //
  //     // SSE 연결 오류 처리 및 재연결 로직
  //     eventSource.current.onerror = async () => {
  //       eventSource.current?.close();
  //       setTimeout(fetchSSE, 60000); // 3초 후 재연결 시도
  //     };
  //
  //     // SSE 연결이 성공적으로 열렸을 때의 처리
  //     eventSource.current.onopen = () => {
  //       // 연결이 열렸을 때의 추가 처리 가능
  //     };
  //   };
  //
  //   // SSE 연결을 초기화
  //
  //   fetchSSE();
  //
  //   // 컴포넌트 언마운트 시 SSE 연결 해제
  //   return () => {
  //     eventSource.current?.close();
  //   };
  // }, [tokenData]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const { data } = useQuery({
    queryFn: alarmCheck,
    refetchOnWindowFocus: true, // 윈도우가 다시 포커스될 때 쿼리 재실행
    refetchInterval: 60000, // 1분마다 쿼리 재실행 (밀리초 단위)
    staleTime: 0,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navList = (
    <div className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-10">
      {data?.data ? (
        <div onClick={() => navigate("/alarm", { state: me?.memberId })}>
          <Lottie
            options={defaultOptions}
            height={32}
            width={32}
            isClickToPauseDisabled={true}
          />
        </div>
      ) : (
        isLogin && (
          <CustomImage
            color={"#000"}
            width={isMobile ? 18 : 24}
            src={
              themeData === "light" || themeData === ""
                ? "/img/standbuy/icon/alarm-bell.svg"
                : "/img/standbuy/icon/alarm-bell-white.svg"
            }
            alt={"알림"}
            className={"cursor-pointer"}
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else if (isLogin) {
                navigate("/alarm", { state: me?.memberId });
              }
            }}
          />
        )
      )}

      <Switcher />
      {isLogin ? (
        <div className="hover:text-[#42BC7F] subtitle-3 dark:text-darkText transition duration-300 ease-in-out ">
          <Link to="/cart">
            <Text className={"dark:text-darkText click-a"} fontWeight={500}>
              장바구니
            </Text>
          </Link>
        </div>
      ) : (
        <span
          className="flex items-center subtitle-3 dark:text-darkText cursor-pointer"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            }
          }}
        >
          장바구니
        </span>
      )}

      {isLogin && me?.mobileVerification ? (
        <div className="hover:text-[#42BC7F] subtitle-3 dark:text-darkText transition duration-300 ease-in-out">
          <Link to="/upload">
            <Text className={"dark:text-darkText"} fontWeight={500}>
              업로드
            </Text>
          </Link>
        </div>
      ) : (
        <span
          className="flex items-center subtitle-3 dark:text-darkText cursor-pointer"
          onClick={() => {
            if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            } else if (isLogin && !me?.mobileVerification) {
              dispatch(niceModalFetched({ show: true }));
            }
          }}
        >
          업로드
        </span>
      )}

      {isLogin ? (
        <div className="cursor-pointer">
          <ProfileAvatarMenu />
        </div>
      ) : (
        <Link
          to="/login"
          className="flex items-center subtitle-3 dark:text-darkText"
        >
          로그인
        </Link>
      )}
    </div>
  );

  return (
    <div
      className={`max-h-[120px] w-full ${
        location?.pathname.includes("/feed") ||
        location?.pathname.includes("studio") ||
        location?.pathname === "/pay-list" ||
        location?.pathname === "/fof-list" ||
        location?.pathname === "/fof-credit" ||
        location?.pathname === "/exchange-detail" ||
        location?.pathname === "/terms" ||
        location?.pathname === "/policy" ||
        location?.pathname === "/cert"
          ? "fixed"
          : "sticky"
      } top-0 z-[999]`}
    >
      <Navbar className="!shadow-none h-max max-w-full rounded-none px-4 py-[15px] lg:px-12 border border-t-0 border-l-0 border-r-0 border-b-1 border-slate-50 dark:bg-darkBg dark:border-transparent dark:border-[#1a1a1a] transition duration-1000 ease-out">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex">
            <img
              src={
                themeData === "light" || themeData === ""
                  ? "/img/standbuy/logo/logo.svg"
                  : "/img/standbuy/logo/logo-white.svg"
              }
              alt="스탠바이"
              onClick={() => {
                if (location?.pathname === "/feed" && location.search === "") {
                  window.location.reload();
                } else {
                  navigate("/feed", { replace: true });
                }
              }}
              width={150}
              className="cursor-pointer"
            />
          </div>

          {isMobile ? null : (
            <div className="relative w-1/2">
              <Input
                variant="static"
                type="text"
                placeholder="아티스트명, 콘텐츠, 키워드로 검색해보세요!"
                containerProps={{
                  className: "",
                }}
                className="placeholder:text-[#707070] !bg-gray-50 border-none rounded-md pb-4 px-4 dark:!bg-darkBg dark:border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
                labelProps={{
                  className: "hidden",
                }}
                onChange={(e: any) => {
                  setKeyword(e.target.value);
                }}
                onKeyUp={(event: any) => {
                  if (event.key === "Enter") {
                    navigate(`/feed/${keyword}`, {
                      state: keyword,
                      replace: true,
                    });
                  }
                }}
              />

              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="!absolute right-3 top-[13px] dark:text-[#d1d1d1]"
              />
            </div>
          )}

          <div className="flex items-center gap-4">
            <div className="hidden lg:block">{navList}</div>

            {location.pathname === "/" ? null : (
              <>
                {isMobile && isLogin && (
                  <>
                    {isLogin && me?.mobileVerification ? (
                      <div className="hover:text-[#42BC7F] subtitle-3 dark:text-darkText transition duration-300 ease-in-out">
                        <Link to="/upload">
                          {/* eslint-disable-next-line react/jsx-no-undef */}
                          <Chip
                            variant="outlined"
                            value="업로드"
                            className="rounded-full"
                          />
                        </Link>
                      </div>
                    ) : (
                      <span
                        className="flex items-center subtitle-3 dark:text-darkText cursor-pointer"
                        onClick={() => {
                          if (isLogin === false) {
                            dispatch(modalFetched({ show: true }));
                          } else if (isLogin && !me?.mobileVerification) {
                            dispatch(niceModalFetched({ show: true }));
                          }
                        }}
                      >
                        <Chip
                          variant="outlined"
                          value="업로드"
                          className="rounded-full"
                        />
                      </span>
                    )}
                  </>
                )}
                {isMobile && isLogin && (
                  <>
                    {data?.data ? (
                      <div
                        onClick={() =>
                          navigate("/alarm", { state: me?.memberId })
                        }
                      >
                        <Lottie
                          className={"dark:bg-white"}
                          options={defaultOptions}
                          height={32}
                          width={32}
                          isClickToPauseDisabled={true}
                        />
                      </div>
                    ) : (
                      <img
                        src={"/img/standbuy/icon/alarm-bell.svg"}
                        alt={"알림"}
                        className={"cursor-pointer"}
                        onClick={() =>
                          navigate("/alarm", { state: me?.memberId })
                        }
                      />
                    )}
                  </>
                )}
                {isMobile && isLogin && (
                  <>
                    <img
                      src={"/img/google-icons/search.svg"}
                      alt="검색"
                      className="cursor-pointer"
                      onClick={() => dispatch(searchFlagFetched(!searchFlag))}
                    />
                  </>
                )}
                {isMobile && isLogin && (
                  <IconButton
                    variant="text"
                    className="h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={() => setMobileModal({ show: true })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </IconButton>
                )}
              </>
            )}

            {isMobile && !isLogin ? (
              <>
                <span
                  className="flex items-center subtitle-3 dark:text-darkText cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <Chip
                    variant="outlined"
                    value="로그인"
                    className="rounded-full"
                  />
                </span>
              </>
            ) : (
              <>
                {location.pathname === "/" ? (
                  <span
                    className="flex items-center subtitle-3 dark:text-darkText cursor-pointer"
                    onClick={async () => {
                      if (isLogin) {
                        await del(`${API_DEFAULT}/user/auth/sign-out`, {
                          accessToken: accessToken,
                          refreshToken: refreshToken,
                          browser: browserData,
                        });
                        signOut({ dispatch: dispatch });
                      }
                    }}
                  >
                    <Chip
                      variant="outlined"
                      value="로그아웃"
                      className="rounded-full"
                    />
                  </span>
                ) : null}
              </>
            )}
          </div>
        </div>
      </Navbar>

      {isMobile && searchFlag ? (
        <div className={"mb-4"}>
          <Input
            variant="static"
            type="text"
            placeholder="아티스트명, 콘텐츠, 키워드로 검색해보세요!"
            containerProps={{
              className: "",
            }}
            className="placeholder:text-[#707070] !bg-gray-50 border-none rounded-md pb-4 px-4 dark:!bg-darkBg dark:border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
            labelProps={{
              className: "hidden",
            }}
            onChange={(e: any) => {
              setKeyword(e.target.value);
            }}
            onKeyUp={(event: any) => {
              if (event.key === "Enter") {
                navigate(`/feed/${keyword}`, {
                  state: keyword,
                  replace: true,
                });
              }
            }}
          />
        </div>
      ) : null}

      {mobileModal.show && (
        <MobileAvatarModal
          open={mobileModal.show}
          onClose={() => setMobileModal({ show: false })}
          navList={navList}
        />
      )}

      {searchModal.show && (
        <SearchModal
          open={searchModal.show}
          onClose={() => setSearchModal({ show: false })}
        />
      )}
    </div>
  );
}
