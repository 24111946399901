import React, { useEffect, useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import CustomCheckBox from "../../../components/design-system/CustomCheckBox";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import JoinTermsModal from "../modal/JoinTermsModal";
import JoinPolicyModal from "../modal/JoinPolicyModal";
import { useMediaQuery } from "react-responsive";

export default function JoinCheckForm() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [policyModal, setPolicyModal] = useState<any>({
    show: false,
    data: "",
  });
  const [allAgree, setAllAgree] = useState(false); // 전체 동의

  const [agreements, setAgreements] = useState({
    mustOne: false,
    mustTwo: false,
    mustThree: false,
    choiceAd: false,
    choiceInfo: false,
  });

  const handleAgreementChange = (event: any) => {
    const { name, checked } = event.target;

    setAgreements((prev: any) => ({ ...prev, [name]: checked }));
    const allChecked = Object.values({ ...agreements, [name]: checked }).every(
      (value: any) => value === true,
    );
    setAllAgree(allChecked);
  };

  const handleAllAgreementChange = (event: any) => {
    const { checked } = event.target;

    setAgreements((prev: any) =>
      Object.keys(prev).reduce((newAgreements: any, agreementKey: any) => {
        return {
          ...newAgreements,
          [agreementKey]: checked,
        };
      }, {}),
    );

    setAllAgree(checked);
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div className="flex flex-col gap-12">
            <Text className="subtitle-2 dark:text-darkText">
              귀한 곳에 귀한 분이!{""}
              <Text className="subtitle-2 dark:text-darkText">
                아티스트가 되려면 약관에 동의해주세요.
              </Text>
            </Text>

            <div className="flex flex-col justify-center gap-6">
              <CustomCheckBox
                label="약관에 모두 동의"
                labelStyle="subtitle-3 dark:text-darkText"
                checked={allAgree}
                onChange={handleAllAgreementChange}
                ripple={false}
              />
              <div className="h-[1px] bg-gray-200"></div>
              <CustomCheckBox
                label="만 14세 이상 (필수)"
                checked={agreements.mustOne}
                onChange={handleAgreementChange}
                name="mustOne"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              <div className="flex  items-center justify-between">
                <CustomCheckBox
                  label="스탠바이 이용 약관 (필수)"
                  checked={agreements.mustTwo}
                  onChange={handleAgreementChange}
                  name="mustTwo"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setInfoModal({ show: true })}
                >
                  이용약관
                </Text>
              </div>

              <div
                className={`flex ${isMobile ? "flex-col" : "items-center justify-between"} `}
              >
                <CustomCheckBox
                  label="개인정보 수집 및 이용 동의 (필수)"
                  checked={agreements.mustThree}
                  onChange={handleAgreementChange}
                  name="mustThree"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setPolicyModal({ show: true })}
                >
                  (보기)
                </Text>
              </div>

              <CustomCheckBox
                label="광고성 정보 수신 동의 (선택)"
                checked={agreements.choiceAd}
                onChange={handleAgreementChange}
                name="choiceAd"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              {/* <CustomCheckBox
              label="개인정보 수집 및 이용 동의 (선택)"
              checked={agreements.choiceInfo}
              onChange={handleAgreementChange}
              name="choiceInfo"
              labelStyle="dark:text-darkText"
              ripple={false}
            /> */}
            </div>
          </div>

          <div className="mt-12">
            <FillButton
              text={"다음"}
              disabled={
                allAgree ||
                (agreements.mustOne &&
                  agreements.mustTwo &&
                  agreements.mustThree)
                  ? false
                  : true
              }
              onClick={() =>
                navigate("/join-form", {
                  state: { ad: agreements.choiceAd },
                })
              }
              className="w-full"
              size="lg"
            />
          </div>
        </div>
      ) : (
        <Card>
          <div className="flex flex-col gap-12">
            <Text className="subtitle-2 dark:text-darkText">
              귀한 곳에 귀한 분이!{""}
              <Text className="subtitle-2 dark:text-darkText">
                아티스트가 되려면 약관에 동의해주세요.
              </Text>
            </Text>

            <div className="flex flex-col justify-center gap-6">
              <CustomCheckBox
                label="약관에 모두 동의"
                labelStyle="subtitle-3 dark:text-darkText"
                checked={allAgree}
                onChange={handleAllAgreementChange}
                ripple={false}
              />
              <div className="h-[1px] bg-gray-200"></div>
              <CustomCheckBox
                label="만 14세 이상 (필수)"
                checked={agreements.mustOne}
                onChange={handleAgreementChange}
                name="mustOne"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              <div className="flex  items-center justify-between">
                <CustomCheckBox
                  label="스탠바이 이용 약관 (필수)"
                  checked={agreements.mustTwo}
                  onChange={handleAgreementChange}
                  name="mustTwo"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setInfoModal({ show: true })}
                >
                  이용약관
                </Text>
              </div>

              <div
                className={`flex ${isMobile ? "flex-col" : "items-center justify-between"} `}
              >
                <CustomCheckBox
                  label="개인정보 수집 및 이용 동의 (필수)"
                  checked={agreements.mustThree}
                  onChange={handleAgreementChange}
                  name="mustThree"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setPolicyModal({ show: true })}
                >
                  (보기)
                </Text>
              </div>

              <CustomCheckBox
                label="광고성 정보 수신 동의 (선택)"
                checked={agreements.choiceAd}
                onChange={handleAgreementChange}
                name="choiceAd"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              {/* <CustomCheckBox
              label="개인정보 수집 및 이용 동의 (선택)"
              checked={agreements.choiceInfo}
              onChange={handleAgreementChange}
              name="choiceInfo"
              labelStyle="dark:text-darkText"
              ripple={false}
            /> */}
            </div>
          </div>

          <div className="mt-12">
            <FillButton
              text={"다음"}
              disabled={
                allAgree ||
                (agreements.mustOne &&
                  agreements.mustTwo &&
                  agreements.mustThree)
                  ? false
                  : true
              }
              onClick={() =>
                navigate("/join-form", {
                  state: { ad: agreements.choiceAd },
                })
              }
              className="w-full"
              size="lg"
            />
          </div>
        </Card>
      )}

      {infoModal.show && (
        <JoinTermsModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}

      {policyModal.show && (
        <JoinPolicyModal
          open={policyModal.show}
          onClose={() => setPolicyModal({ show: false })}
          data={policyModal.data}
        />
      )}
    </>
  );
}
