import React, { useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import NiceForm from "../../passwordFind/card/NiceForm";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";

export default function NiceFormCard() {
  const navigate = useNavigate();
  return (
    <Card>
      <div className="flex flex-col ">
        <div className="leading-[2.5rem]">
          <Text className="dark:text-darkText" fontSize={32} fontWeight={900}>
            본인인증으로
          </Text>
          <Text className="dark:text-darkText " fontSize={32} fontWeight={900}>
            스탠바이 시작.
          </Text>
        </div>

        <div className={"mt-[30px]"}>
          <Text className="dark:text-darkText" fontSize={16} fontWeight={400}>
            더욱 안전한 스탠바이 생활을 위해
          </Text>

          <Text className="dark:text-darkText" fontSize={16} fontWeight={400}>
            본인인증을 진행하고 있어요.
          </Text>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <NiceForm />
      </div>

      <div className="flex flex-col items-center justify-center">
        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={() => navigate(-1)}
        >
          뒤로가기
        </Text>
      </div>
    </Card>
  );
}
