import React, { useEffect, useState } from "react";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { setDetail } from "../../store/reducer/feedSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AssetsMode from "../../components/feed/modal/AssetsMode";
import FeedContent from "../../components/feed/modal/FeedContent";
import SellerInfo from "../../components/feed/modal/SellerInfo";
import FeedComment from "../../components/feed/modal/FeedComment";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";

export default function SharePage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const { state } = useContext();
  const { get } = useAxios();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState<any>();
  const { detail } = useAppSelector((state) => state.feed);
  const params = useParams();

  async function onFetch() {
    const payload = {
      contentId: params?.contentId || state?.contentId,
    };
    const res = await get(`${API_DEFAULT}/contents/search/detail`, payload);

    if (res?.success) {
      dispatch(setDetail(res?.data));
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    onFetch();
  }, [state, id]);

  useEffect(() => {
    const temp = location?.pathname;
    const params = temp.split("/");
    setId(params);
  }, [location]);

  // useEffect(() => {
  //   if (detail?.status !== "ENABLE") {
  //     alert("이미 삭제된 콘텐츠 입니다.");
  //     navigate("/feed", { replace: true });
  //   }
  // }, []);

  return (
    <div className="w-full flex flex-col h-full">
      <div
        className={`${isMobile ? "mt-4 px-4" : "mt-[50px] mx-auto"}  flex flex-col justify-center max-w-[1280px]`}
      >
        <div className={`flex ${isMobile && "flex-col"} gap-10`}>
          <AssetsMode />
          <FeedContent />
        </div>
        <div>
          <SellerInfo detail={detail} state={params?.contentId} />
          <FeedComment detail={detail} state={params?.contentId} />
        </div>
      </div>
    </div>
  );
}
