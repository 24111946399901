//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import LocationFeedMap from "./card/LocationFeedMap";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import {
  setLocationList,
  setLocationListInit,
  setPositions,
  setSearchResult,
} from "../../store/reducer/locationSlice";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import StockListCard from "../feed/stock-list/StockListCard";
import { shallowEqual } from "react-redux";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SearchByMap from "../../lib/modules/google-map/SearchByMap";
import debounce from "lodash/debounce";
import { useMediaQuery } from "react-responsive";
import { instance } from "../../api";

export default function LocationFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const [loading, setLoading] = useState(false);
  const bounds = useAppSelector((state) => state.bounds);
  const zoom = useAppSelector((state) => state.bounds.zoom);
  const location = useLocation();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { positions, locationList } = useAppSelector((state) => state.location);
  const [itmes, setItems] = useState<any>([]);
  const { lat, lng } = useAppSelector(
    (state) => state.geoLocation,
    shallowEqual,
  );
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [pos, setPos] = useState({
    lat: !lat ? 37.56462664995 : lat,
    lng: !lng ? 127.02878456871 : lng,
  });

  useEffect(() => {
    setPos({
      lat: !lat ? 37.56462664995 : lat,
      lng: !lng ? 127.02878456871 : lng,
    });
  }, [lat]);

  const debouncedOnAllLocation = useRef(
    debounce(async ({ latMin, latMax, lonMin, lonMax, zoom }) => {
      // setLoading(true);
      const payload = {
        latMin: latMin,
        latMax: latMax,
        lonMin: lonMin,
        lonMax: lonMax,
        zoom: zoom,
      };
      const res = await instance.post(
        `${API_DEFAULT}/contents/search/markers`,
        payload,
      );
      if (res?.data.success) {
        dispatch(setSearchResult([]));
        dispatch(setPositions(res?.data.data));
        // setLoading(false);
      } else {
        // 에러 처리
      }
    }, 500),
  ).current;

  useEffect(() => {
    if (bounds.ne && bounds.sw && location.pathname === "/location-feed") {
      setTimeout(() => {
        debouncedOnAllLocation({
          latMin: bounds?.ne.lat,
          latMax: bounds?.sw.lat,
          lonMin: bounds?.ne.lng,
          lonMax: bounds?.sw.lng,
          zoom: zoom,
        });
      }, 500);
    }
  }, [bounds]);

  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;

    const res = await instance.post(`${API_DEFAULT}/contents/search/location`, {
      latMin: 36.507468472827576,
      latMax: 38.60617845599798,
      lonMin: 125.24388600000003,
      lonMax: 128.75951100000003,
      page: nextGroupKey,
      size: count,
    });

    if (res?.data.success) {
      nextItems = res?.data.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px] mb-10">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">지도로 찾기</Text>
          </div>

          <BorderWrap className="p-6 mb-4">
            <SearchByMap />
          </BorderWrap>

          <div style={{ position: "relative" }}>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 검은색 배경
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1, // 맵 위에 오버레이 되도록 설정
                }}
              >
                <FadeLoader color={"#fff"} />
              </div>
            )}
            <LocationFeedMap lat={lat} lng={lng} />
          </div>

          <div className="mt-10 mb-4">
            <Text
              fontSize={16}
              fontWeight={600}
              color="#303030"
              className="dark:text-darkText"
            >
              검색 장소와 관련된 게시물
            </Text>
          </div>

          <JustifiedInfiniteGrid
            // className="container"
            loading={
              <FadeLoader
                color={
                  themeData === "light" || themeData === ""
                    ? "#121212"
                    : "#ffffff"
                }
              />
            }
            placeholder={
              <div className="placeholder">추가 데이터를 불러오는 중...</div>
            }
            stretch={true}
            passUnstretchRow={true}
            sizeRange={[228, 228]}
            stretchRange={[144, 320]}
            {...(isMobile && { columnRange: [0, 2] })}
            threshold={3000}
            gap={8}
            onRequestAppend={(e) => {
              const nextGroupKey = (+e.groupKey! || 0) + 1;
              // 같은 그룹키는 무시
              if (nextGroupKey === groupKey) {
                return;
              }
              setGroupKey(nextGroupKey);
              e.wait();

              setTimeout(async () => {
                const add = await getItems(nextGroupKey, 20);
                if (add.length === 0) {
                  e.ready();
                  setContentId(null);
                  return true;
                }

                dispatch(setLocationList({ data: add, reset: false }));
                setItems([...itmes, ...add]);
                e.ready();
              }, 3000);
            }}
          >
            {itmes?.length < 1 ? (
              <div className="flex flex-col items-center justify-center">
                <Text className="subtitle-2 dark:text-darkText">
                  조회된 피드가 없습니다.
                </Text>
                <Text className="label-1 dark:text-darkText">
                  필터를 사용해서 다시 조회 해주세요!
                </Text>
              </div>
            ) : (
              itmes?.map((item: any, index: any) => (
                <StockListCard
                  data-grid-groupkey={item.groupKey}
                  key={index}
                  index={index}
                  item={item}
                  onClick={undefined}
                />
              ))
            )}
          </JustifiedInfiniteGrid>
        </div>

        <div className="fixed bottom-10 right-10">
          <div className="flex flex-col justify-center items-center gap-4">
            <FeedNav />

            <IconButton
              //@ts-ignore
              className="rounded-full px-[28px] py-[28px]"
              placeholder={undefined}
              children={undefined}
              size="lg"
              onClick={moveToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} />
            </IconButton>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
`;
