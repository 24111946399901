import styled from "styled-components";

import { useEffect, useRef } from "react";
import { GOOGLE_MAP_KEY } from "../../api/api";
import {
  getGeocoder,
  googleMapPanTo,
} from "../../lib/modules/google-map/google-map-util";
import { useAppDispatch, useAppSelector } from "../../store/store";
import GoogleMap from "../../lib/modules/google-map";
import useGeolocation from "../hooks/useGeoLocation";
import {
  setClickGeoPosition,
  setGeoPosition,
} from "../../store/reducer/geoLocationSlice";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function UploadMap({ lat, lng }: any) {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const location = useLocation();
  // const locations = useGeolocation();
  const dispatch = useAppDispatch();
  // const { clickLat, clickLng } = useAppSelector((state) => state.geoLocation);
  const createdMarkers = useRef([]) as any; //------------------------------- 생성된 마커들을 저장할 배열

  const mapRef = useRef(null) as any;
  useEffect(() => {
    if (!window?.google) return;

    googleMapPanTo({
      lat: lat || 37.5642135,
      lng: lng || 127.0016985,
    });
    if (mapRef?.current) {
      // mapRef?.current?.addMarker({
      //   lat: clickLat || locations?.coordinates?.lat,
      //   lng: clickLng || locations?.coordinates?.lng,
      // });
      mapRef?.current?.addMarker({
        lat: lat || 37.5642135,
        lng: lng || 127.0016985,
      });
    }
  }, [lat, lng, location]);

  return (
    <div>
      {isMobile ? (
        <GoogleMap
          createdMarkers={createdMarkers}
          ref={mapRef}
          isCenter
          API_KEY={GOOGLE_MAP_KEY || ""}
          style={{
            width: "340px",
            height: "18.75rem",
            borderRadius: "10px",
          }}
          onClick={(e: any) => {
            dispatch(setGeoPosition({ lat: e?.lat, lng: e?.lng }));
          }}
          mapOptions={{
            zoom: 10,
            center: {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }], // POI 숨기기
              },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }], // 대중교통 정보 숨기기
              },
            ],
          }}
          positions={[
            {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
          ]}
        ></GoogleMap>
      ) : (
        <GoogleMap
          createdMarkers={createdMarkers}
          ref={mapRef}
          isCenter
          API_KEY={GOOGLE_MAP_KEY || ""}
          style={{
            width: "880px",
            height: "18.75rem",
            borderRadius: "10px",
          }}
          onClick={(e: any) => {
            dispatch(setGeoPosition({ lat: e?.lat, lng: e?.lng }));
          }}
          mapOptions={{
            zoom: 10,
            center: {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }], // POI 숨기기
              },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }], // 대중교통 정보 숨기기
              },
            ],
          }}
          positions={[
            {
              lat: lat || 37.5642135,
              lng: lng || 127.0016985,
            },
          ]}
        ></GoogleMap>
      )}
    </div>
  );
}
