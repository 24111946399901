import React, { useEffect } from "react";
import SectionOne from "./card/SectionOne";
import SectionTwo from "./card/SectionTwo";
import SectionThree from "./card/SectionThree";
import SectionFour from "./card/SectionFour";
import SectionFive from "./card/SectionFive";
import SectionSix from "./card/SectionSix";
import SectionSeven from "./card/SectionSeven";
import SectionEight from "./card/SectionEight";
import SectionNinth from "./card/SectionNinth";
import SectionEnd from "./card/SectionEnd";
import { visitAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import uuid from "react-uuid";
import SectionIntro from "./card/SectionIntro";
import { useMediaQuery } from "react-responsive";
import MobileSectionFour from "./card/MobileSectionFour";
import MobileSectionTwo from "./card/MobileSectionTwo";
import useDeviceSize from "../../components/hooks/useDeviceSize";
import MobileLandingPage from "../../components/layout/MobileLandingPage";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { browserFetched } from "../../store/reducer/browerSlice";
import { LOCAL_STORAGE } from "../../lib/const";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";

export default function LandingPage() {
  const { isDesktop } = useDeviceSize();
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const isLogin = localStorage.getItem(LOCAL_STORAGE.LOGIN);
  const navigate = useNavigate();
  const { me } = useContext();
  // const browserData = useAppSelector(
  //   (state) => state.browser.browser,
  //   shallowEqual,
  // );
  //
  // async function onVisitantFetch({ browserData }: any) {
  //   console.log(browserData);
  //
  //   const res = await visitAxios.post(
  //     `${API_DEFAULT}/user/visit?browser=${browserData}`,
  //   );
  // }
  //
  // useEffect(() => {
  //   const visitData: any = localStorage.getItem("id");
  //
  //   if (visitData && browserData) {
  //     return;
  //   } else {
  //     localStorage.setItem("id", uuid());
  //     onVisitantFetch({ browserData });
  //   }
  // }, [browserData]);

  // useEffect(() => {
  //   console.log(isLogin, me);
  //   if (isLogin && me?.agreementSelectiveTerms === false) {
  //     navigate("/social-check");
  //   } else if (isLogin && me?.agreementSelectiveTerms) {
  //     navigate("/feed");
  //   } else if (!isLogin) {
  //     navigate("/");
  //   }
  // }, [isLogin, me]);

  return (
    <>
      {isDesktop ? (
        <div className={"max-w-full"}>
          <SectionOne />
          {!isMobile && <SectionIntro />}
          {isMobile ? <MobileSectionTwo /> : <SectionTwo />}
          <SectionThree />
          {isMobile ? <MobileSectionFour /> : <SectionFour />}
          {!isMobile && <SectionFive />}
          {!isMobile && <SectionSix />}
          {!isMobile && <SectionSeven />}
          {!isMobile && <SectionEight />}
          {!isMobile && <SectionNinth />}
          {!isMobile && <SectionEnd />}
          <CustomFooter />
        </div>
      ) : (
        <MobileLandingPage />
      )}
    </>
  );
}
