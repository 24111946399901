//@ts-nocheck
import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import Text from "../design-system/Text";
import styled from "styled-components";
import { WHITE } from "../../styles/color";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useContext } from "../../util/useContext";
import CartEndModal from "../../pages/cart/modal/CartEndModal";
import EventModal from "../event/EventModal";
import { useSelector } from "react-redux";
import { Input } from "@material-tailwind/react";
import { modalFetched } from "../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../store/store";

const MapBanner = styled.div`
  border-radius: 24px;
  background-image: url("/img/standbuy/background/map-bg.png");
`;

const NoticeBanner = styled.div`
  background-image: url("/img/standbuy/background/event-bg.svg");
  border-radius: 24px;
`;

const StandBuyBanner = styled.div`
  background-image: url("/img/standbuy/background/studio-bg.svg");
  border-radius: 24px;
`;

export default function Banner() {
  const dispatch = useAppDispatch();
  const { searchFlag } = useSelector((state: any) => state.mobile);
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const { me, isLogin } = useContext();
  const [eventModalSet, setEventModalSet] = useState<any>({
    show: false,
    data: "",
  });

  const [keyword, setKeyword] = useState("");

  return (
    <>
      {/*{isMobile && searchFlag ? (*/}
      {/*  <div className={"mb-4"}>*/}
      {/*    <Input*/}
      {/*      variant="static"*/}
      {/*      type="text"*/}
      {/*      placeholder="아티스트명, 콘텐츠, 키워드로 검색해보세요!"*/}
      {/*      containerProps={{*/}
      {/*        className: "",*/}
      {/*      }}*/}
      {/*      className="placeholder:text-[#707070] !bg-gray-50 border-none rounded-md pb-4 px-4 dark:!bg-darkBg dark:border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"*/}
      {/*      labelProps={{*/}
      {/*        className: "hidden",*/}
      {/*      }}*/}
      {/*      onChange={(e: any) => {*/}
      {/*        setKeyword(e.target.value);*/}
      {/*      }}*/}
      {/*      onKeyUp={(event: any) => {*/}
      {/*        if (event.key === "Enter") {*/}
      {/*          navigate(`/feed/${keyword}`, {*/}
      {/*            state: keyword,*/}
      {/*            replace: true,*/}
      {/*          });*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {isMobile ? (
        <div className={`flex gap-2`}>
          {/*<MapBanner*/}
          {/*  className="dark:bg-darkBanner !max-h-[208px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1"*/}
          {/*  onClick={() => navigate("/location-feed")}*/}
          {/*>*/}
          {/*  <div className="h-full flex flex-col justify-center items-center gap-4">*/}
          {/*    <Text color={WHITE} fontSize={28} fontWeight={800}>*/}
          {/*      지도로 찾기*/}
          {/*    </Text>*/}
          {/*    <Text color={WHITE} fontSize={16}>*/}
          {/*      지도에서 내가 원하는 콘텐츠를 찾아요*/}
          {/*    </Text>*/}
          {/*  </div>*/}
          {/*</MapBanner>*/}

          <NoticeBanner
            className="dark:bg-darkBanner !min-h-[130px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1"
            onClick={() => setEventModalSet({ show: true })}
          >
            <div className="h-full flex flex-col justify-center items-center gap-4">
              <div className="flex flex-col items-center">
                <Text
                  color={WHITE}
                  fontSize={isMobile ? 16 : 28}
                  fontWeight={800}
                >
                  9월 BEST 콘텐츠
                </Text>
                <Text color={WHITE} fontSize={16} fontWeight={800}>
                  EVENT
                </Text>
              </div>
            </div>
          </NoticeBanner>

          <StandBuyBanner
            className="dark:bg-darkBanner !min-h-[130px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1 border border-gray-200 dark:border-none"
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/my-studio/${me?.memberId}`);
              }
            }}
          >
            <div className="h-full flex flex-col justify-center items-center gap-4">
              <Text color={WHITE} fontSize={16} fontWeight={800}>
                내 스튜디오
              </Text>
            </div>
          </StandBuyBanner>
        </div>
      ) : (
        <div className={`${isMobile ? " flex-col" : ""} flex gap-6`}>
          <MapBanner
            className="dark:bg-darkBanner !max-h-[208px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1"
            onClick={() => navigate("/location-feed")}
          >
            <div className="h-full flex flex-col justify-center items-center gap-4">
              <Text color={WHITE} fontSize={28} fontWeight={800}>
                지도로 찾기
              </Text>
              <Text color={WHITE} fontSize={16}>
                지도에서 내가 원하는 콘텐츠를 찾아요
              </Text>
            </div>
          </MapBanner>

          <NoticeBanner
            className="dark:bg-darkBanner !max-h-[208px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1"
            onClick={() => setEventModalSet({ show: true })}
          >
            <div className="h-full flex flex-col justify-center items-center gap-4">
              <div className="flex flex-col items-center">
                <Text color={WHITE} fontSize={28} fontWeight={800}>
                  9월 BEST 콘텐츠
                </Text>
                <Text color={WHITE} fontSize={28} fontWeight={800}>
                  EVENT
                </Text>
              </div>
              <Text color={WHITE} fontSize={16}>
                이벤트에 참여해보세요
              </Text>
            </div>
          </NoticeBanner>

          <StandBuyBanner
            className="dark:bg-darkBanner !min-h-[208px] cursor-pointer bg-cover bg-no-repeat bg-left flex-1 border border-gray-200 dark:border-none"
            onClick={() => {
              if (isLogin === false) {
                dispatch(modalFetched({ show: true }));
              } else {
                navigate(`/my-studio/${me?.memberId}`);
              }
            }}
          >
            <div className="h-full flex flex-col justify-center items-center gap-4">
              <Text color={WHITE} fontSize={28} fontWeight={800}>
                내 스튜디오
              </Text>
              <Text color={WHITE} fontSize={16}>
                업로드하고 판매중인 콘텐츠를 확인해요
              </Text>
            </div>
          </StandBuyBanner>
        </div>
      )}

      {eventModalSet.show && (
        <EventModal
          open={eventModalSet.show}
          onClose={() => setEventModalSet({ show: false })}
        />
      )}
    </>
  );
}
