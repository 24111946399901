//@ts-nocheck
import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import FilterBar from "../../components/feed/filter/FilterBar";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { API_DEFAULT } from "../../api/api";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setStockList } from "../../store/reducer/mainStock";
import StockListCard from "./stock-list/StockListCard";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FeedNav } from "../../components/design-system/FeedNav";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { instance } from "../../api";
import NoticeModal from "../../components/common/NoticeModal";

export default function NewFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { stockList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [itmes, setItems] = useState<any>([]);
  // const [isPopupShow, setPopupShow] = useState(true);
  // const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });

  // const closePopup = (expireDays: any) => {
  //   let expire: any = new Date();
  //   expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
  //   localStorage.setItem("popupNoShow", expire.getTime());
  // };
  //
  // const checkPopupClose = () => {
  //   const expireDay: any = localStorage.getItem("popupNoShow");
  //   let today: any = new Date();
  //
  //   if (today.getTime() > expireDay) {
  //     // 이렇게 하면 localStorage에 아무것도 저장되어 있지 않을 경우 undefined 거나 null 이므로 true를 반환한다.
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  //
  // const closePopupToday = () => {
  //   closePopup(1); // 하루 동안 표시 안 할 것임을 명시
  //   setPopupShow(false); // 오늘 하루 안 보기 누름과 동시에 팝업 사라짐
  // };
  //
  // useEffect(() => {
  //   checkPopupClose() ? setPopupShow(true) : setPopupShow(false);
  //   // 최초 컴포넌트가 마운트되었을 때, 팝업을 표시할 지 말지 조회
  // }, []);

  // useEffect(() => {
  //   setInfoModal({ show: true });
  // }, []);

  useEffect(() => {
    dispatch(setStockList({ data: itmes, reset: false }));
  }, [itmes]);

  // 진입 페이지
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await instance.post(
      `${API_DEFAULT}/contents/search/main?page=${nextGroupKey}&size=${count}`,
    );

    if (res?.data?.success) {
      nextItems = res.data.data; // 응답 구조에 따라 수정 필요
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div
        className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10`}
      >
        <div className="my-[50px] mb-[30px]">
          <Banner />
        </div>

        <div className="mb-[30px] flex gap-5 overflow-auto">
          <FilterBar itmes={itmes} />
        </div>

        <JustifiedInfiniteGrid
          // loading={
          //   <FadeLoader
          //     color={
          //       themeData === "light" || themeData === ""
          //         ? "#121212"
          //         : "#ffffff"
          //     }
          //   />
          // }
          placeholder={
            <div className="placeholder">추가 데이터를 불러오는 중...</div>
          }
          stretch={true}
          passUnstretchRow={true}
          sizeRange={[228, 228]}
          stretchRange={[144, 320]}
          {...(isMobile && { columnRange: [0, 2] })}
          threshold={3000}
          gap={8}
          onRequestAppend={(e) => {
            const nextGroupKey = (+e.groupKey! || 0) + 1;
            if (nextGroupKey === groupKey) {
              return;
            }
            setGroupKey(nextGroupKey);
            e.wait();

            setTimeout(async () => {
              const add = await getItems(nextGroupKey, 15);
              if (add?.length === 0) {
                e.ready();
                return true;
              }

              dispatch(setStockList({ data: add, reset: false }));
              setItems([...itmes, ...add]);
              e.ready();
            }, 1);
          }}
        >
          {stockList.length < 1 ? (
            <div className="flex flex-col items-center justify-center">
              <Text className="subtitle-2 dark:text-darkText">
                조회된 피드가 없습니다.
              </Text>
              <Text className="label-1 dark:text-darkText">
                필터를 사용해서 다시 조회 해주세요!
              </Text>
            </div>
          ) : (
            stockList.map((item: any, index: any) => (
              <StockListCard
                data-grid-groupkey={item.groupKey}
                key={index}
                index={index}
                item={item}
                onClick={undefined}
              />
            ))
          )}
        </JustifiedInfiniteGrid>
      </div>

      <div className="fixed bottom-10 right-10 z-[9999]">
        <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
          <FeedNav />

          <IconButton
            //@ts-ignore
            className="rounded-full px-[28px] py-[28px]"
            placeholder={undefined}
            children={undefined}
            size="lg"
            onClick={moveToTop}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
        </div>
      </div>
      {/*{infoModal.show && isPopupShow && (*/}
      {/*  <NoticeModal*/}
      {/*    open={infoModal.show}*/}
      {/*    onClose={() => setInfoModal({ show: false })}*/}
      {/*    // data={infoModal.data}*/}
      {/*    // closePopupToday={closePopupToday}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}
