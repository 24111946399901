import React from "react";
import Text from "../../../components/design-system/Text";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { useMediaQuery } from "react-responsive";

export default function SectionFour() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();
  const { isLogin, me } = useContext();
  const { scrollYProgress } = useScroll();
  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [isMobile ? "-400%" : "-200%", "300%"],
  );

  return (
    <div className="h-fit flex flex-col relative bg-[#000] overflow-hidden">
      <div className="flex flex-col justify-center items-center gap-3 mt-[200px]">
        <Text
          fontSize={isMobile ? 24 : 70}
          fontWeight={600}
          color="#fff"
          className="leading-[0.5]"
        >
          저작권과 초상권이 확보된
        </Text>
        <Text
          fontSize={isMobile ? 24 : 70}
          fontWeight={600}
          color="#fff"
          className="mb-24"
        >
          콘텐츠라면
        </Text>

        <motion.div style={{ x }}>
          <div className="max-w-full flex gap-20">
            <img src="/img/standbuy/landing/slide-1.png" />
            <img src="/img/standbuy/landing/slide-2.png" />
            <img src="/img/standbuy/landing/slide-3.png" />
            <img src="/img/standbuy/landing/slide-4.png" />
          </div>
        </motion.div>
        <div className={`my-40 w-full flex ${!isMobile && "justify-evenly"} `}>
          {isMobile ? (
            <img src="/img/standbuy/landing/coin.svg" className={"ml-4"} />
          ) : (
            <img src="/img/standbuy/landing/coin.svg" />
          )}

          <div className="flex flex-col gap-10 mt-10">
            {!isMobile && (
              <div className="flex flex-col items-end">
                <Text
                  fontSize={60}
                  fontWeight={600}
                  color="#fff"
                  className="leading-[1]"
                >
                  돈이되는 상품으로
                </Text>
                <Text fontSize={60} fontWeight={600} color="#fff">
                  사용권 저작권 팔기!
                </Text>
              </div>
            )}

            {!isMobile && (
              <div className="flex flex-col items-end ">
                <Text
                  fontSize={24}
                  fontWeight={400}
                  color="#fff"
                  className="leading-[1]"
                >
                  스탠바이는 당신의 콘텐츠에 가치를 부여해요.
                </Text>
                <Text fontSize={24} fontWeight={400} color="#fff">
                  오늘도 스탠바이에서 콘텐츠를 판매해보세요!
                </Text>

                {isLogin && me?.agreementSelectiveTerms && (
                  <div
                    className="mt-[70px] rounded-full bg-[#42B77F] py-3 px-7 cursor-pointer w-[440px] flex justify-center items-center"
                    onClick={() => navigate(isLogin ? "/feed" : "/login")}
                  >
                    <img
                      src="/img/standbuy/landing/green-standbuy.svg"
                      alt=""
                      width={140}
                    />
                    <Text
                      fontSize={24}
                      fontWeight={600}
                      color="#fff"
                      className="mb-1 ml-2"
                    >
                      에서 팔아보기
                    </Text>
                  </div>
                )}

                {isLogin && me?.agreementSelectiveTerms === false && (
                  <div
                    className="mt-[70px] rounded-full bg-[#42B77F] py-3 px-7 cursor-pointer w-[440px] flex justify-center items-center"
                    onClick={() => navigate("/social-check")}
                  >
                    <img
                      src="/img/standbuy/landing/green-standbuy.svg"
                      alt=""
                      width={140}
                    />
                    <Text
                      fontSize={24}
                      fontWeight={600}
                      color="#fff"
                      className="mb-1 ml-2"
                    >
                      에서 팔아보기
                    </Text>
                  </div>
                )}

                {isLogin === false && (
                  <div
                    className="mt-[70px] rounded-full bg-[#42B77F] py-3 px-7 cursor-pointer w-[440px] flex justify-center items-center"
                    onClick={() => navigate("/login")}
                  >
                    <img
                      src="/img/standbuy/landing/green-standbuy.svg"
                      alt=""
                      width={140}
                    />
                    <Text
                      fontSize={24}
                      fontWeight={600}
                      color="#fff"
                      className="mb-1 ml-2"
                    >
                      에서 팔아보기
                    </Text>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
