//@ts-nocheck
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  IconButton,
} from "@material-tailwind/react";
import Text from "./Text";
import { FooterTextColor } from "../../styles/color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export function FeedNav() {
  const isMobile = useMediaQuery({ maxWidth: 820 });
  const navigate = useNavigate();

  return (
    <Popover placement="left">
      <PopoverHandler>
        <IconButton className="rounded-full px-[28px] py-[28px]">
          <FontAwesomeIcon icon={faQuestion} size="lg" />
        </IconButton>
      </PopoverHandler>
      <PopoverContent className={`z-[9999] ${isMobile && "max-w-[300px]"}`}>
        <div className="flex items-center gap-4 divide-x-2 z-[9999]">
          <Text
            className="cursor-pointer"
            color={FooterTextColor}
            onClick={() => navigate("/")}
            fontSize={12}
            fontWeight={600}
          >
            스탠바이
          </Text>
          <Text
            className="cursor-pointer pl-4"
            color={FooterTextColor}
            onClick={() => navigate("/notice")}
            fontWeight={600}
            fontSize={12}
          >
            공지사항
          </Text>
          <Text
            className=" pl-4 cursor-pointer"
            color={FooterTextColor}
            onClick={() => navigate("/terms")}
            fontWeight={600}
            fontSize={12}
          >
            이용약관
          </Text>
          <Text
            fontWeight={700}
            className="pl-4 cursor-pointer"
            color={FooterTextColor}
            onClick={() => navigate("/policy")}
            fontSize={12}
          >
            개인정보 처리방침
          </Text>
          <Text
            className="pl-4 cursor-pointer"
            color={FooterTextColor}
            onClick={() => navigate("/location-terms")}
            fontWeight={600}
            fontSize={12}
          >
            위치기반서비스 이용약관
          </Text>
          {/* <Text
            className="profile-menu-text pl-4 cursor-pointer"
            color={FooterTextColor}
          >
            아티스트 약관
          </Text>
          <Text
            className="profile-menu-text pl-4 cursor-pointer"
            color={FooterTextColor}
          >
            튜토리얼
          </Text> */}
        </div>
      </PopoverContent>
    </Popover>
  );
}
